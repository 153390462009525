import React from 'react'

export default class WhatPeopleThink extends React.Component {
    render() {

        return <section id="testimonials" className="testimonials section">
            <div className="container">
                <h2 className="title text-center">What do people think?</h2>
                <div className="row">
                    {
                        this.props.testimonials.map((testimonial) =>
                            <div className="item col-md-4 col-12">
                                <div className="quote-box">
                                    <i className="fas fa-quote-left"/>
                                    <blockquote className="quote" dangerouslySetInnerHTML={{__html: testimonial.quote}} />
                                </div>
                                <div className="people row">
                                    <img
                                        className="rounded user-pic col-md-5 col-12"
                                        src={testimonial.image}
                                        alt=""
                                    />
                                    <p className="details text-center float-left">
                                        <span className="name">{testimonial.name}</span>
                                        <span className="title">{testimonial.title}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    }
}