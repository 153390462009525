import React from 'react'

export default class PricingPlans extends React.Component {
    render() {
        return <section id="pricing" className="pricing section has-pattern">
            <div className="container">
                <h2 className="title text-center">Choose your plan today</h2>
                <div className="price-cols row">
                    <div className="items-wrapper col-lg-10 col-12 ml-lg-auto mr-lg-auto">
                        <div className="row">
                            <div className="item price-1 col-md-4 col-12 text-center">
                                <div className="item-inner">
                                    <div className="heading">
                                        <h3 className="title">Personal</h3>
                                        <p className="price-figure"><span className="price-figure-inner"><span
                                            className="currency">$</span><span className="number">5</span><br/><span
                                            className="unit"> per month</span></span></p>
                                    </div>
                                    <div className="content">
                                        <ul className="list-unstyled feature-list">
                                            <li><i className="fas fa-check"/>1 user</li>
                                            <li><i className="fas fa-check"/>10 GB storage</li>
                                            <li className="disabled"><i className="fas fa-times"/>Email priority support</li>
                                            <li className="disabled"><i className="fas fa-times"/>Free update</li>
                                            <li className="disabled"><i className="fas fa-times"/>24/7 support</li>
                                        </ul>
                                        <a className="btn btn-theme btn-block" href="#">Sign Up</a>

                                    </div>
                                </div>
                            </div>

                            <div className="item price-2 col-md-4 col-12 text-center best-buy">
                                <div className="item-inner">
                                    <div className="heading">
                                        <h3 className="title">Professional</h3>
                                        <p className="price-figure"><span className="price-figure-inner"><span
                                            className="currency">$</span><span className="number">10</span><br/><span
                                            className="unit">per month</span></span></p>
                                    </div>
                                    <div className="content">
                                        <ul className="list-unstyled feature-list">
                                            <li><i className="fas fa-check"/>10 users</li>
                                            <li><i className="fas fa-check"/>30 GB storage</li>
                                            <li><i className="fas fa-check"/>Email priority support</li>
                                            <li><i className="fas fa-check"/>Free update</li>
                                            <li className="disabled"><i className="fas fa-times"/>24/7 support</li>

                                        </ul>
                                        <a className="btn btn-theme btn-block" href="#">Sign Up</a>
                                    </div>
                                    <div className="ribbon">
                                        <div className="text">Popular</div>
                                    </div>
                                </div>
                            </div>
                            <div className="item price-3 col-md-4 col-12 text-center">
                                <div className="item-inner">
                                    <div className="heading">
                                        <h3 className="title">Business</h3>
                                        <p className="price-figure"><span className="price-figure-inner"><span
                                            className="currency">$</span><span className="number">25</span><br/><span
                                            className="unit">per month</span></span></p>
                                    </div>
                                    <div className="content">
                                        <ul className="list-unstyled feature-list">
                                            <li><i className="fas fa-check"/> Unlimited user</li>
                                            <li><i className="fas fa-check"/>100 GB storage</li>
                                            <li><i className="fas fa-check"/>Email priority support</li>
                                            <li><i className="fas fa-check"/>Free update</li>
                                            <li><i className="fas fa-check"/>24/7 support</li>
                                        </ul>
                                        <a className="btn btn-theme btn-block" href="#">Sign Up</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    }

}