import React from 'react'

export default class Features extends React.Component {
	render() {
		return <section id="features" className="features section">
			<div className="container">
				<div className="row">
					<h2 className="title text-center sr-only">Features</h2>
					<div className="item col-lg-3 col-md-6 col-12 text-center">
						<div className="icon">
							<i className="fas fa-cloud-upload-alt"/>
						</div>
						<div className="content">
							<h3 className="title">AWS Ready</h3>
							<p>Our application runs in a stable cloud environment which makes our work faster, easier
								and more cost-effective, helping us to introduce more innovations without worry for
								scalability.</p>
						</div>
					</div>
					<div className="item col-lg-3 col-md-6 col-12 text-center">
						<div className="icon">
							<i className="fas fa-rocket"/>
						</div>
						<div className="content">
							<h3 className="title">Work fast</h3>
							<p>Thanks to working with scrum and agile technologies we are able to quickly achieve
								desirable results. It leaves us more time to precisely plan and perform our goals.</p>
						</div>
					</div>
					<div className="item col-lg-3 col-md-6 col-12 text-center">
						<div className="icon">
							<i className="fas fa-users"/>
						</div>
						<div className="content">
							<h3 className="title">We are team</h3>
							<p>We derived our work philosophy from ants. The lesson of this is true teamwork – our
								experts always thrive to achieve a common goal by joint effort.</p>
						</div>
					</div>
					<div className="item col-lg-3 col-md-6 col-12 text-center">
						<div className="icon">
							<i className="fas fa-globe"/>
						</div>
						<div className="content">
							<h3 className="title">Worldwide collaboration </h3>
							<p>Our head office is situated in Poland but we are not limited to
								our region. In our history we cooperated with companies in UK, USA, Canada, Spain,
								Malta, Switzerland, Germany, Latvia and
								Singapore </p>
						</div>
					</div>
				</div>
			</div>
		</section>
	}
}