import React from 'react';
import config from "../config";

export default class ContactForm extends React.Component {

    render() {
        return <>
            <section id="contact" className="contact section has-pattern">
                <div className="container">
                    <h2 className="title text-center">Contact US</h2>
                    <div className="row text-center">
                        <div className="intro col-lg-6 col-12 ml-lg-auto mr-lg-auto">
                            <p>{config.company.name.full}<br/>
                                Siedziba: {config.company.headquarter}<br/>
                                {config.company.court}<br/>
                                Wpisana do rejestru KRS: {config.company.registration.krs}<br/>
                                NIP: {config.company.registration.nip} REGON: {config.company.registration.regon}<br/>
                                {config.company.shareCapital}
                            </p>
                            <ul className="list-unstyled contact-details">
                                <li><i className="fas fa-envelope"/><a
                                    href={"mailto:" + config.contact.email}>{config.contact.email}</a></li>
                                <li><i className="fas fa-phone-square"/>{config.contact.phone}</li>
                            </ul>
                        </div>
                        <div className="intro col-lg-6 col-12 ml-lg-auto mr-lg-auto">
                            <p>{config.company.accounts.bank}, SWIFT: {config.company.accounts.swift} <br/>{config.company.accounts.main}</p>
                            <p>{config.company.address.street}, <br/>{config.company.address.zip} {config.company.address.city}
                            </p>
                        </div>
                    </div>
                    <div className="text-center">
                        <ul className="social-icons list-inline">
                        <li className="list-inline-item"><a href={config.social.company.social.facebook}><i
                                className="fab fa-facebook-f"/></a></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    }

}