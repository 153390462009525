import React from 'react'
import {Link} from "react-router-dom";


export default class Header extends React.Component {
    render() {
        return <header id="top" className="header fixed-top">
            <div className="container">
                <h1 className="logo float-left">
                    <Link to="/#story">
                        <img id="logo-image" className="logo-image" src={"/assets/images/logo/logo.png"} alt="Logo"/>
                        <span className="logo-title">TerraLabsMedia</span>
                    </Link>
                </h1>
                <nav id="main-nav" className="main-nav navbar-light navbar-expand-md" role="navigation">
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="navbar-collapse collapse justify-content-end" id="navbar-collapse">
                        <ul className="nav navbar-nav">

                            <li className="active nav-item sr-only"><Link to="/">Home</Link></li>
                            <li className="nav-item "><Link className="nav-link" to="/#story">Story</Link></li>
                            {/*<li className="nav-item"><a className="nav-link" href="#brands">Brands</a></li>*/}
                            {/*<li className="nav-item"><a className="nav-link" href="#how">How it works</a></li>*/}
                            {/*<li className="nav-item"><Link className="nav-link" to="/praca/">Praca</Link></li>*/}
                            <li className="nav-item"><Link className="nav-link" to="/#features">Features</Link></li>
                            {/*<li className="nav-item"><a className="nav-link" href="#testimonials">Testimonials</a></li>*/}
                            {/*<li className="nav-item"><Link className="nav-link" to="/faq">FAQ</Link></li>*/}

                            <li className="nav-item last"><a className="nav-link" href="/#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    }
}