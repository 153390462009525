
import React from 'react'
import config from "../config";

export default class OurStory extends React.Component {
    render() {
        return <section id="story" className="story section has-pattern">
            <div className="container">
                <div className="row">
                    <div className="content col-md-6 col-12">

                        <h2 className="title text-center">Story behind our company</h2>
                        <p>Animal and plant breeders often encounter the same problem – what happens with the
                            charges during their absence (e.g. holiday trip, hospital visit etc.)? They would like to know if
                            their companions are safe and provided with ideal breeding conditions.</p>
                        <p>Have they been given enough food? Are all pumps and water components in good condition
                            and with optimal parameters? Will the lighting be turned on and off in time?</p>
                        <p>Our company offers a reliable system that allows you to monitor, control and respond to
                            changes in living environment.With mobile applications that provide instant
                            access to the remote control, traceability of breeding conditions
                            and tools to adjust them, we have developed unique and innovative solutions that will
                            resolve your problems. Our communication system and the ability to remotely react to
                            events ensures the best quality and safety of your farm.
                        </p>
                    </div>
                    <div className="team col-md-5 col-12 mr-auto ml-auto">
                        <div className="row">
                            <div className="member col-lg-6 col-12 text-center">
                                <img className="rounded" src={config.social.srebrny.image} alt={config.social.srebrny.title + config.social.srebrny.name}/>
                                <p className="name">{config.social.srebrny.name}</p>
                                <p className="title">{config.social.srebrny.title}</p>
                                <ul className="connect list-inline">
                                    <li className="list-inline-item"><a href={config.social.srebrny.social.facebook}><i className="fab fa-facebook"/></a></li>
                                    <li className="list-inline-item"><a href={config.social.srebrny.social.linkedin}><i className="fab fa-linkedin"/></a></li>
                                    <li className="row-end list-inline-item"><a href={config.social.srebrny.social.github}><i className="fab fa-github"/></a></li>
                                    <li className="row-end list-inline-item"><a href={config.social.srebrny.social.website}><i className="fas fa-sitemap"/></a></li>
                                </ul>
                            </div>
                            {/*<div className="member col-lg-6 col-12 text-center">*/}
                            {/*    <img className="rounded" src={config.social.agronomka.image} alt={config.social.agronomka.title + config.social.agronomka.name}/>*/}
                            {/*    <p className="name">{config.social.agronomka.name}</p>*/}
                            {/*    <p className="title">{config.social.agronomka.title}</p>*/}
                            {/*    <ul className="connect list-inline">*/}
                            {/*        <li className="list-inline-item"><a href={config.social.agronomka.social.facebook}><i className="fab fa-facebook"/></a></li>*/}
                            {/*        <li className="list-inline-item"><a href={config.social.agronomka.social.linkedin}><i className="fab fa-linkedin"/></a></li>*/}
                            {/*        <li className="row-end list-inline-item"><a href={config.social.agronomka.social.github}><i className="fab fa-github"/></a></li>*/}
                            {/*        {config.social.agronomka.social.website > 0 &&*/}
                            {/*            <li className="row-end list-inline-item"><a href={config.social.agronomka.social.website}><i className="fas fa-sitemap"/></a></li>*/}
                            {/*        }*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}