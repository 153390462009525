import React from 'react'

export default class OurBrands extends React.Component {
    render() {
        return <section id="brands" className="brands section">
            <div className="container">
                <div className="press text-center">
                    <h2 className="note">Our Brands</h2>
                    <ul className="list-inline">
                        {
                            this.props.brands.map((brand) =>
                                <li className="list-inline-item">
                                    <a href={brand.url}>
                                        <img
                                            className="img-fluid"
                                            src={brand.image}
                                            alt={brand.name}/>
                                    </a>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </section>
    }
}