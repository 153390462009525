
import React from 'react';

export default class Footer extends React.Component {

    render() {
        return <footer className="footer">
                <div className="container text-center">
                    <small className="copyright">Created and pushed into cloud by <a href="https://pycode.biz/?utm_source=terralabsmedia&utm_medium=footer&utm_campaign=terralabsmedia_footer_link" target="_blank" rel="noopener noreferrer">pyCode.biz</a>&copy;</small>
                </div>
            </footer>
    }

}