
import React from 'react'

export default class Promote extends React.Component {
    render() {
        return <section id="promo" className="promo section offset-header has-pattern">
            <div className="container">
                <div className="row">
                    <div className="overview col-lg-8 col-12">
                        <h2 className="title">Delta helps you promote your mobile app</h2>
                        <ul className="summary">
                            <li>Showcase and promote your mobile app to the web</li>
                            <li>Demonstrate how the app works</li>
                            <li>Provide answers to common questions</li>
                            <li>Tell the world about the story behind the app</li>
                        </ul>
                        <div className="download-area">
                            <ul className="list-inline">
                                <li className="ios-btn list-inline-item"><a href="#">Download from the App Store</a>
                                </li>
                                <li className="android-btn list-inline-item"><a href="#">Get it from Google Play</a>
                                </li>
                            </ul>
                            <div className="note text-center">
                                <p>30% OFF - Now only $0.99<br/>Offer ends 31st March</p>
                                <span className="left-arrow"/>
                                <span className="right-arrow"/>
                            </div>
                        </div>
                    </div>

                    <div className="phone iphone iphone-x col-lg-4 col-12 ">
                        <div className="iphone-holder phone-holder">
                            <div className="iphone-holder-inner">
                                <div className="slider flexslider">
                                    <ul className="slides">
                                        <li>
                                            <img src="/assets/images/iphone/iphone-x-slide-1.png" alt=""/>
                                        </li>
                                        <li>
                                            <img src="/assets/images/iphone/iphone-x-slide-2.png" alt=""/>
                                        </li>
                                        <li>
                                            <img src="/assets/images/iphone/iphone-x-slide-3.png" alt=""/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}