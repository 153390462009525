import React from 'react'


export default class PracaAsystentKa extends React.Component {
	render() {
		return <section id="faq" className="faq section">
			<div className="container">
				<h2 className="title text-center">Oferta pracy - asystent / resercher</h2>
				<div className="row text-justify">
					<div className="col-md-12 col-12">

						<p><strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames
							ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet,
							ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae
								est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
							pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit
							amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
							sagittis tempus lacus enim ac dui. in turpis pulvinar
							facilisis. Ut felis.</p>

						<h3>Kim jesteśmy? Jaki problem mamy?</h3>

						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis
							aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend,
							libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam.
							Vivamus pretium ornare est.</p>

						<ol>
							<li>Podcast - najpewniej jedyny na świecie - pomoc w przygotowaniu odcinków</li>
							<li>Nowo powstający sklep internetowy, który będziesz mieć okazje rozwijać</li>
							<li>Obsługa korespondencji</li>
							<li>Pomoc w zbieraniu materiałów, informacji tworzenie raportów</li>
						</ol>

						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis
							aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend,
							libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam.
							Vivamus pretium ornare est.</p>

						<h2>Twoje przyszłe zadania</h2>
						<ul>
							<li>Redagowanie tekstów na podstawie wytycznych</li>
							<li>Aktualizacja produktów w sklepie internetowym</li>
							<li>Obsługa korespondencji oraz oraz wysyłka małogabarytowych paczek dla klientów sklepu
							</li>
							<li>Redagowanie pism, maili firmowych oraz komunikacja z klientami przez FB</li>
							<li>Zamawianie produktów i pół produktów oraz zakupów potrzebnych do funkcjonowania biura
							</li>
						</ul>

						<h2>Wymagania</h2>
						<ul>
							<li>Umiejętność redagowania testów oraz artykułów</li>
							<li>Umiejętność wyszukiwania informacji w internecie</li>
							<li>Komunikatywność i otwartość</li>
							<li>Bardzo dobra znajomość Google Docs oraz Google Sheets</li>
							<li>Podstawowa znajomość metodyk Kanban oraz Kaizen będzie dużym atutem</li>
							<li>Jeżeli dodatkowo znasz Trello.com, canva.com, map myśli i wiesz co to kaizen oraz kanban - duży plus</li>
						</ul>

						<h2>Oferta</h2>
						<ul>
							<li>Praca w dużej mierze zdalna - wymagane, jednak abyś mogła sprawnie obsługiwać pocztę. Jeden dzień w tygodniu i godzina na wysyłkę bęzie ok :) </li>
							<li>Praca asynchroniczna - możesz pracować praktycznie w dowolnych godzinach</li>
							<li>Możliwość nauki obsługi systemów e-commerce</li>
							<li>Możliwość nauki </li>
							<li>Współpraca przy wieloaspektowych projektach, które łączą świat technologii oraz światy
								roślin i zwierząt
							</li>
						</ul>

					</div>
				</div>
				<div className="more text-center">
					<h4 className="title">Jeżeli jest to dla ciebie interesujące to </h4>
					<a className="btn btn-lg btn-theme" href="https://forms.gle/7uBPSwaii81SxLEDA">Wypełnij
						formularz<i
							className="far fa-arrow-alt-circle-right"/></a>
				</div>
			</div>
		</section>
	}
}