let config;
config = {
    brands: [
        {
            url: "https://wormfarm.com/",
            name: 'wormfarm',
            description: "",
            image: "/assets/images/press/press-1.png",
            visible: false
        },
        {
            url: "https://formicopedia.org/",
            name: "formicopedia.org",
            description: "",
            image: "/assets/images/press/press-2.png",
            visible: true
        },
        {
            url: "https://formicarium.pl/",
            name: "formicarium",
            description: "",
            image: "/assets/images/press/press-3.png",
            visible: true
        },
        {
            url: "https://terralabsmedia.com/rodos",
            name: "rodos",
            description: "",
            image: "/assets/images/press/press-4.png",
            visible: true
        },
        {
            url: "https://pyCode.biz/",
            name: 'pyCode.biz',
            description: "pyCode.biz is a software house who help us building amazing software for our projects",
            image: "/assets/images/press/press-1.png",
            visible: false
        },
        {
            url: "https://penny-budget.com/",
            name: 'Penny budget',
            description: "Powerfull and multi managment system for budgeting and money managment. ",
            image: "/assets/images/press/press-1.png",
            visible: true
        },
    ],
    testimonials: [
        {
            quote: "<a href=\"#\">@Delta</a> I love this #app. Lorem ipsum dolor sit amet, consectetur\n adipiscing elit. #Mobile",
            name: "Tomas",
            title: "CEO",
            image: "/assets/images/people/people-1.png",
            visible: false
        },
        {
            quote: "<a href=\"#\">@Delta</a> I love this #app. Lorem ipsum dolor sit amet, consectetur\n \"adipiscing elit. #Mobile",
            name: "Jurgen",
            title: "CTO",
            image: "/assets/images/people/people-2.png",
            visible: false
        },
        {
            quote: "<a href=\"#\">@Delta</a> I love this #app. Lorem ipsum dolor sit amet, consectetur\n \"adipiscing elit. #Mobile",
            name: "Janusz",
            title: "HR Manager",
            image: "/assets/images/people/people-3.png",
            visible: false
        },
        {
            quote: "<a href=\"#\">@Delta</a> I love this #app. Lorem ipsum dolor sit amet, consectetur\n \"adipiscing elit. #Mobile",
            name: "Zenon",
            title: "Owner at private-shop.com",
            image: "/assets/images/people/people-4.png",
            visible: false
        },
        {
            quote: "<a href=\"#\">@Delta</a> I love this #app. Lorem ipsum dolor sit amet, consectetur\n \"adipiscing elit. #Mobile",
            name: "",
            title: "Oktawiusz",
            image: "/assets/images/people/people-5.png",
            visible: false
        },
    ],
    faqs: [],
    contact: {
        email: "contact@terralabsmedia.com",
        phone: "+48.785835825",
    },
    social: {
        company: {
            title: "",
            meta: {
                title: "",
                desc: "",
                tags: "",
            },
            social: {
                facebook: "https://fb.me/terralabsmedia",
                website: "https://www.terralabsmedia.com/",
            }
        },
        srebrny: {
            image: "/assets/images/team/pawel-srebniak.png",
            name: "Paweł Srebniak",
            title: "Founder & Chairman",
            social: {
                facebook: "https://www.facebook.com/srebrny",
                linkedin: "https://www.linkedin.com/in/pawe%C5%82-srebniak-3454a7a4/",
                github: "https://github.com/srebrny",
                website: "https://www.srebniak.pl/",
            },
        },
        agronomka: {
            image: "/assets/images/team/member-2.png",
            name: "Magda",
            title: "Co-Founder, PCM",
            social: {
                facebook: "https://www.facebook.com/",
                linkedin: "https://www.linkedin.com/",
            },
        },
    },
    company: {
        headquarter: "Wrocław",
        name: {full: "TerraLabsMedia Spółka z ograniczoną odpowiedzialnością", short: "TerraLabsMedia"},
        gdpr: {},
        registration: {
            nip: "8982254142",
            regon: "38486952000000",
            krs: "0000813641",
            date: "2019-11-14",
            shareCapital: "5000 zł w całości wniesiony gotówką"
        },
        court: "SĄD REJONOWY DLA WROCŁAWIA-FABRYCZNEJ WE WROCŁAWIU, VI WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO",
        address: {
            street: "Księcia Józefa Poniatowskiego 2/6",
            zip: "50-326",
            city: "Wrocław"
        },
        accounts: {
            main: "PL08 1140 2004 0000 3502 8389 5209",
            swift: "BREXPLPWMBK",
            bank: "MBank"
        }
    }
};

export default config;