import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ContactForm from "./utils/contactForm";
import Footer from "./utils/footer";
import Header from "./utils/headers";
import Features from "./utils/features";
import OurStory from "./utils/ourStory";
import PricingPlans from "./utils/pricingPlans";
import OurBrands from "./utils/ourBrands";
import config from "./config";
import HowItWorks from "./utils/howItWorks";
import Promote from "./utils/promote";
import WhatPeopleThink from "./utils/whatPeopleThink";
import PracaAsystentKa from "./Components/PracaAsystentka/praca-asystent-ka";


export default function App() {
    return (
        <Router>
            <Switch>
                {/*<Route path="/praca/">*/}
                {/*    <Praca/>*/}
                {/*</Route>*/}
                {/*<Route path="/faq">*/}
                {/*    <Faq/>*/}
                {/*</Route>*/}
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
        </Router>
    );
}

function Home() {
    return (
        <div className="App">
            <Header/>
            <OurStory/>
            {/*<OurBrands brands={config.brands.filter(brand => brand.visible !== false)}/>*/}
            {/*<HowItWorks/>*/}
            <Features/>
            {/*{config.testimonials.filter(testimonial => testimonial.visibile !== false) && <WhatPeopleThink testimonials={config.testimonials.filter(testimonial => testimonial.visibile !== false)}/>            }*/}
            {/*<Promote/>*/}
            {/*{config.faqs.filter(faq => faq.visibile !== false) && <Faq/> }*/}
            {/*<PricingPlans/>*/}
            <ContactForm/>
            <Footer/>
        </div>
    );

}

function Faq() {
    return (
        <div className="App">
            <Header/>
            <OurStory/>
            {/*<OurBrands brands={config.brands.filter(brand => brand.visible !== false)}/>*/}
            <HowItWorks/>
            <Features/>
            {/*{config.testimonials.filter(testimonial => testimonial.visibile !== false) && <WhatPeopleThink testimonials={config.testimonials.filter(testimonial => testimonial.visibile !== false)}/>            }*/}
            <Promote/>
            {/*{config.faqs.filter(faq => faq.visibile !== false) && <Faq/> }*/}
            <PricingPlans/>
            <ContactForm/>
            <Footer/>
        </div>
    );
}

function Praca() {
    return (
        <div className="App">
            <Header/>
            <PracaAsystentKa/>
            <Footer/>
        </div>
    );
}


