
import React from 'react'

export default class HowItWorks extends React.Component {
    render() {
        return <section id="how" className="how section has-pattern">
            <div className="container">
                <div className="row">
                    <div className="content col-md-6 col-12 order-md-12 text-center">
                        <h2 className="title">How it works</h2>
                        <p className="intro">You can <a
                            href="http://blog.sensortower.com/blog/2013/07/19/3-ways-to-create-a-great-ios-app-demo-video-without-breaking-the-bank/"
                            target="_blank">create a video demo</a> for your app to demonstrate how the app works. You
                            can also <a href="http://www.qrstuff.com/" target="_blank">generate QR codes</a> for the app
                            download links.</p>
                        <div className="qr-codes d-none d-lg-block">
                            <div className="note">
                                <p>Scan the QR codes</p>
                                <span className="left-arrow"/>
                                <span className="right-arrow"/>
                            </div>
                            <div className="row">
                                <div className="item col-md-6">
                                    <img src="/assets/images/qrcodes/qr-1.png" alt=""/>
                                    <p>Download for iOS</p>
                                </div>
                                <div className="item col-md-6">
                                    <img src="/assets/images/qrcodes/qr-1.png" alt=""/>
                                    <p>Download for Andriod</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="video-container" className="video-container col-md-6 col-12 order-md-1">
                        <div className="embed-responsive embed-responsive-16by9">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}